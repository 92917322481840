import React, { useContext, lazy } from 'react';
import { SelectOption } from 'info-ui-library';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivilege } from '../../../utils/ClientUtils';
import { UserContext } from '../../../user-context/UserContext';
import styled from 'styled-components';
import { getMultiValueObject } from '../../../components/ComponentsCommonUtils';
import { isConfigObjectEditable } from '../../../composite-entity/form/CardGenerators';
import DataGridToolTip from './DataGridToolTip';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { getOptions } from '../../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDiv = styled.div`
  color: #0000ffba;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: underline;
`;

const EditableMultiSelectCell = ({
  cell,
  row,
  column: { id },
  updateMyData,
}) => {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const ffPrivilege = getPrivilege(
    cell.column.configProp.privileges,
    userContext.getCurrentRole()
  );
  const [isEditable, setEditable] = React.useState(false);

  const [value, setValue] = React.useState([]);
  const existingCards = useSelector((state) => state.appState.cardsData);
  //WHEN INLINE EDIT IS DISABLE AND ADD NEW ROW IS ENABLE
  let cellEditable =
    row.original &&
    row.original['expander'] === 'NewRow' &&
    row.values[cell.column.primaryDBCode] === ''
      ? true
      : false;

  let rowData = row.original;
  Object.assign(rowData, userContext.getUserDetail);
  let columnIsEditibale = isConfigObjectEditable(
    true,
    cell.column.privilege,
    cell.column.editabilityRegex,
    rowData,
    'EDIT',
    true
  );

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;
  let isDisabled =
    ffPrivilege === 'VIEW' ||
    cell.column.configProp.isHyperlink ||
    cell.column.configProp.hyperLink
      ? true
      : false;

  let optionList = [];
  if (
    cell.column.configProp &&
    optionList.length === 0 &&
    cell.column.configProp.multivalueList
  ) {
    optionList = cell.column.processDropDownValues(
      cell.column.configProp.multivalueList,
      cell.column.parentProps.intl.locale,
      optionList
    );
  } else if (
    cell.column.configProp &&
    optionList.length === 0 &&
    cell.column.cardsData
  ) {
    let referenceID =
      cell.column.formId +
      '_' +
      cell.column.occuranceNumber +
      '_' +
      cell.column.configProp.configObjectId;
    optionList = cell.column.cardsData.options[referenceID]
      ? cell.column.cardsData.options[referenceID]
      : [];
  }

  React.useEffect(() => {
    if (optionList.length > 0) {
      let isFound = true;
      let opTionIdList = [];
      optionList.forEach((option) => {
        opTionIdList.push(option.id);
      });
      let valueList = cell.value ? cell.value.split(',') : [];
      valueList.map((optionVal) => {
        if (isFound && !opTionIdList.includes(optionVal)) {
          isFound = false;
        }
      });

      if (!isFound && cell.value) {
        dispatch(
          getOptions(
            cell.column.parentFormId,
            cell.column.formId,
            cell.column.configProp,
            cell.column.occuranceNumber,
            cell.column.parentProps.nodeId,
            cell.column.referralId,
            cell.column.parentProps.location
          )
        );
      }

      if (
        isFound &&
        !isDisabled &&
        (cell.column.isDefaultEditable || isEditable || cellEditable) &&
        cell.column?.changeEnable
      ) {
        let valueAsAnObject = getMultiValueObject(cell.value, optionList);
        setValue(valueAsAnObject);
      } else if (isFound) {
        let optionData = '';
        if (cell.value) {
          optionList.forEach(function (option, index) {
            let splitData = cell.value.split(',');
            splitData.forEach(function (value, index) {
              if (option.id == value) {
                if (!optionData) {
                  optionData = option.label;
                } else {
                  optionData = optionData + ' , ' + option.label;
                }
              }
            });
          });
        }
        if (cell.value) {
          let selectedValue = {};
          selectedValue['id'] = cell.value;
          selectedValue['value'] = cell.value;
          selectedValue['label'] = optionData;
          setValue(selectedValue);
        } else {
          setValue(null);
        }
      }
    }
  }, [cell.column, isEditable,cell.column.filteredRows]);

  React.useEffect(() => {
    if (
      existingCards[cell.column.referralId].type === 'PortalDataGrid' &&
      cell.column.configProp &&
      cell.column.configProp.selectItemsReferenceID &&
      !cell.column.configProp.multivalueList &&
      optionList.length === 0
    ) {
    }
  }, [cell.column.occuranceNumber, existingCards[cell.column.referralId].type]);

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  const onChange = (e) => {
    setEditable(false);
    setValue(e);
    let multiValue = '';
    if (e) {
      const optionAsList = e.map((option, index) => {
        if (!multiValue) {
          multiValue = option.id;
        } else {
          multiValue = multiValue + ',' + option.id;
        }
      });
    }

    if (updateMyData) {
      updateMyData(row.index, id, multiValue, row.id);
    }
  };

  const handleBlur = () => {
    setEditable(false);
  };

  const handleClick = () => {
    if (
      (columnIsEditibale && errorData) ||
      (errorData && errorData.path !== row.id.toString())
    ) {
      setEditable(false);
    } else if (columnIsEditibale) {
      setEditable(true);
    }
  };

  let tooltipID = 'selectOptionError_' + id + '_' + row.id.toString();

  return columnIsEditibale &&
    (cell.column.editEnable || cellEditable) &&
    cell.column.isDefaultEditable &&
    cell.column?.changeEnable ? (
    <div style={{ display: 'flex', 'flex-wrap': 'wrap' }}>
      {errorData && errorData['dbCode'] && errorData['dbCode'] === id ? (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          aria-hidden="true"
          data-for={tooltipID}
          data-tip={tooltipID}
          style={{
            'align-self': 'center',
            'padding-left': '3px',
            color: 'red',
          }}
        />
      ) : (
        ''
      )}
      <div
        style={{
          height: '100%',
          position: 'relative',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '98%'
              : '99%',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <SelectOption
          value={value}
          onChange={(e) => onChange(e)}
          options={optionList}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          isDisabled={isDisabled}
          displayType={'SingleLine'}
          isDefaultEditable={cell.column.isDefaultEditable}
          hasError={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? true
              : false
          }
          isInlineGridComponent={true}
          autoFocus={false}
          isMulti={true}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            color: 'white !important',
            zIndex: 9999,
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </div>
  ) : columnIsEditibale &&
    ((cell.column.editEnable && cellEditable && cell.column?.changeEnable) ||
      (cell.column.editEnable && isEditable && cell.column?.changeEnable)) ? (
    <React.Fragment>
      <div
        style={{
          height: '100%',
          position: 'relative',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '98%'
              : '99%',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <SelectOption
          value={value}
          onChange={(e) => onChange(e)}
          options={optionList}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          isDisabled={isDisabled}
          displayType={'SingleLine'}
          isDefaultEditable={cell.column.isDefaultEditable}
          hasError={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? true
              : false
          }
          isInlineGridComponent={true}
          autoFocus={false}
          isMulti={true}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            color: 'white !important',
            zIndex: 9999,
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true && value?.value ? (
        !cell.column.configProp.isHyperlink &&
        !cell.column.configProp.hyperLink ? (
          <div
            onBlur={() => handleBlur()}
            onClick={() => handleClick()}
            style={{
              padding: '10px 10px',
              // width: cell.column.width + 'px',
              // position: 'absolute',
              height: '100%',
            }}
          >
            <DataGridToolTip
              width={cell.column.width}
              tooltipValue={value?.label}
            />
          </div>
        ) : (
          <DataGridToolTip
            width={cell.column.width}
            tooltipValue={value?.label}
          />
        )
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          style={{
            // width: cell.column.width + 'px',
            // position: 'absolute',
            height: '100%',
          }}
          onBlur={() => handleBlur()}
          onClick={() => handleClick()}
        ></div>
      )}
    </React.Fragment>
  );
};

export default React.memo(EditableMultiSelectCell);
